<template>
  <MainLayout>
    <div class="row cl-090D2E">
      <h1 class="mb-2 mb-md-3">
        <strong>Your life situation</strong>
      </h1>
      <p>
        We need some information about you to prepare the necessary documents
        and fulfil compliance obligations.
      </p>
      <p>
        We will pass these details on to the bank to open an investment account
        for you (brokerage account). All data transfers between you,
        Simplewealth and the bank are protected with 128-bit encryption and a
        PGP key.
      </p>
    </div>

    <div
        class="row mt-5"
        v-if="lifeSituation"
    >
      <div class="col-12">
        <div class="col-12 mt-4 d-flex flex-wrap" id="lsMaritalDependents">
          <div class="col-md-6 col-12">
            <div class="sw-label">
              <div class="sw-label-text mb-3">
                Marital status
              </div>
              <div class="d-flex swl-content">
                <div class="sw-radio me-5">
                  <input
                      type="radio"
                      id="maritalStatusRadioSingle"
                      checked
                      name="marital-status-radio"
                      v-model="lifeSituation.maritalStatus"
                      value="single"
                  >
                  <label for="maritalStatusRadioSingle">Single</label>
                </div>
                <div class="sw-radio">
                  <input
                      type="radio"
                      id="maritalStatusRadioMarried"
                      name="marital-status-radio"
                      v-model="lifeSituation.maritalStatus"
                      value="married"
                  >
                  <label for="maritalStatusRadioMarried">Married</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12 mt-md-auto mt-4">
            <div
                class="sw-label"
                :class="{ 'form-error': v$.lifeSituation.dependents.$error }"
            >
              <div class="sw-label-text">
                Number of Dependents
              </div>
              <div
                  id="lsDependents"
                  class="d-flex swl-content"
              >
                <div class="sw-textfield w-100">
                  <input
                      type="text"
                      placeholder="0"
                      v-maska="'##'"
                      v-model="lifeSituation.dependents"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12 mt-45" id="lsEmployment">
          <div
              class="sw-label"
              :class="{ 'form-error': v$.lifeSituation.employment.$error }"
          >
            <div class="sw-label-text">
              Employment type
            </div>
            <div
                id="lsEmploymentType"
                class="d-flex swl-content"
            >
              <v-select
                  :options="employmentTypes"
                  close-on-select
                  placeholder="Choose option"
                  class="sw-select"
                  v-model="lifeSituation.employment"
              />
            </div>
          </div>
        </div>

        <div
            class="col-12 mb-4 mt-6"
            data-test-id="lsEmploymentDetails"
            v-if="
            ['Employed', 'Self-employed'].includes(lifeSituation.employment)
          "
        >
          <h3 class="cl-090D2E">
            Employer information
          </h3>
          <div class="d-flex mt-5 flex-wrap">
            <div
                class="sw-label col-12 mb-45"
                :class="{
                'form-error':
                  v$.lifeSituation.employmentDetails.employer.$error,
              }"
            >
              <div class="sw-label-text">
                Employer
              </div>
              <div
                  id="lsEmployer"
                  class="d-flex swl-content"
              >
                <div class="sw-textfield w-100">
                  <input
                      type="text"
                      placeholder="Enter employer"
                      v-model="lifeSituation.employmentDetails.employer"
                  >
                </div>
              </div>
            </div>

            <div
                class="sw-label col-md-6 col-12 pe-md-2 mb-45"
                :class="{
                'form-error':
                  v$.lifeSituation.employmentDetails.employer_business.$error,
              }"
            >
              <div class="sw-label-text">
                Your employer nature of business
              </div>
              <div
                  id="lsBusinessOccupations"
                  class="d-flex swl-content"
              >
                <v-select
                    :options="businessOccupations"
                    close-on-select
                    placeholder="Choose option"
                    class="sw-select"
                    v-model="lifeSituation.employmentDetails.employer_business"
                    searchable
                    clear-on-select
                />
              </div>
            </div>

            <div
                class="sw-label col-md-6 col-12 ps-md-2 mb-45"
                :class="{
                'form-error':
                  v$.lifeSituation.employmentDetails.occupation.$error,
              }"
            >
              <div class="sw-label-text">
                Your occuption here
              </div>
              <div
                  id="lsBusinessOccupationsOptions"
                  class="d-flex swl-content"
              >
                <v-select
                    :options="businessOccupationsOptions"
                    close-on-select
                    placeholder="Choose option"
                    class="sw-select"
                    v-model="lifeSituation.employmentDetails.occupation"
                    searchable
                    clear-on-select
                />
              </div>
            </div>

            <div
                class="sw-label col-md-6 col-12 pe-md-2 mb-45"
                :class="{
                'form-error':
                  v$.lifeSituation.employmentDetails.employer_address.country
                    .$error,
              }"
            >
              <div class="sw-label-text">
                Employer country
              </div>
              <div
                  id="lsEmployerCountry"
                  class="d-flex swl-content"
              >
                <v-select
                    :options="countries"
                    close-on-select
                    placeholder="Choose option"
                    class="sw-select"
                    v-model="
                    lifeSituation.employmentDetails.employer_address.country
                  "
                    searchable
                    clear-on-select
                />
              </div>
            </div>

            <div
                class="sw-label col-md-6 col-12 ps-md-2 mb-45"
                :class="{
                'form-error':
                  v$.lifeSituation.employmentDetails.employer_address.state
                    .$error,
              }"
            >
              <div class="sw-label-text">
                State/Province
              </div>
              <div
                  id="lsEmployerState"
                  class="d-flex swl-content"
              >
                <v-select
                    :options="states"
                    close-on-select
                    placeholder="Choose option"
                    class="sw-select"
                    v-model="
                    lifeSituation.employmentDetails.employer_address.state
                  "
                    searchable
                    clear-on-select
                />
              </div>
            </div>

            <div
                class="sw-label col-md-6 col-12 pe-md-2 mb-45"
                :class="{
                'form-error':
                  v$.lifeSituation.employmentDetails.employer_address.city
                    .$error,
              }"
            >
              <div class="sw-label-text">
                Employer city
              </div>
              <div
                  id="lsEmployerCity"
                  class="d-flex swl-content"
              >
                <div class="sw-textfield w-100">
                  <input
                      type="text"
                      placeholder="Enter employer city"
                      v-model="
                      lifeSituation.employmentDetails.employer_address.city
                    "
                  >
                </div>
              </div>
            </div>

            <div
                class="sw-label col-md-6 col-12 ps-md-2 mb-45"
                :class="{
                'form-error':
                  v$.lifeSituation.employmentDetails.employer_address
                    .postal_code.$error,
              }"
            >
              <div class="sw-label-text">
                Postal code
              </div>
              <div
                  id="lsEmployerZIP"
                  class="d-flex swl-content"
              >
                <div class="sw-textfield w-100">
                  <input
                      type="text"
                      placeholder="Enter postal code"
                      v-model="
                      lifeSituation.employmentDetails.employer_address
                        .postal_code
                    "
                  >
                </div>
              </div>
            </div>

            <div
                class="sw-label col-12 mb-45"
                :class="{
                'form-error':
                  v$.lifeSituation.employmentDetails.employer_address.addr
                    .$error,
              }"
            >
              <div class="sw-label-text">
                Employer full address
              </div>
              <div
                  id="lsEmployerAddr"
                  class="d-flex swl-content"
              >
                <div class="sw-textfield w-100">
                  <input
                      type="text"
                      placeholder="Enter employer full address"
                      v-model="
                      lifeSituation.employmentDetails.employer_address.addr
                    "
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
            class="col-12"
            :class="{
            'mt-6': !['Employed', 'Self-employed'].includes(
              lifeSituation.employment
            ),
          }"
        >
          <h3
              class="ypd-typo-title"
              v-if="
              ['Employed', 'Self-employed'].includes(lifeSituation.employment)
            "
          >
            Additional income
          </h3>
          <h3
              class="ypd-typo-title"
              v-else
          >
            Sources of Wealth
          </h3>
          <div
              id="lsSOW"
              class="mt-5"
          >
            <div
                class="d-flex mt-4 align-items-center col-12"
                v-if="
                  ['Employed', 'Self-employed'].includes(lifeSituation.employment)
                "
            >
              <div class="col-10 ypd-typo-text">
                Will you use any additional sources of wealth to invest with
                Simplewealth?
              </div>
              <div class="col-2 d-flex justify-content-end">
                <div class="sw-switch">
                  <input
                      type="checkbox"
                      id="switch-1"
                      name="switch-1"
                      v-model="lifeSituation.additionalIncome"
                  >
                  <label for="switch-1"/>
                </div>
              </div>

            </div>
            <div
                class="d-flex mt-4 align-items-center col-12"
                v-else
            >
              <div class="col-10 ypd-typo-text">
                Please fill all categories that contribute to your net worth. Regulations require that we collect this
                information.
              </div>
            </div>

            <div
                id="lsSOWForm"
                class="col-12"
                v-if="lifeSituation.additionalIncome"
            >
              <h5 class="mt-4 ypd-typo-small">
                How is your wealth divided? Write it down in a table (as a
                percentage, total not exceeding 100%)
              </h5>
              <div class="d-flex flex-column">
                <div
                    class="col-12 d-flex align-items-center justify-content-between mt-45"
                    id="totalScroll"
                >
                  <div class="col-6">
                    Income from Employment
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                          id="lsSOWIncomeEmployment"
                          type="number"
                          placeholder="0"
                          max="100"
                          v-model="
                          lifeSituation.additionalIncomeDetails.employment
                        "
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                            class="sw-number_up"
                            onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                            class="sw-number_down"
                            onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 d-flex align-items-center justify-content-between mt-4">
                  <div class="col-6">
                    Allowance / Spousal Income
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                          type="number"
                          placeholder="0"
                          v-model="
                          lifeSituation.additionalIncomeDetails.allowance
                        "
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                            class="sw-number_up"
                            onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                            class="sw-number_down"
                            onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 d-flex align-items-center justify-content-between mt-4">
                  <div class="col-6">
                    Disability / Severance / Unemployment
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                          type="number"
                          placeholder="0"
                          v-model="
                          lifeSituation.additionalIncomeDetails.disability
                        "
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                            class="sw-number_up"
                            onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                            class="sw-number_down"
                            onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 d-flex align-items-center justify-content-between mt-4">
                  <div class="col-6">
                    Inheritance/Gift
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                          type="number"
                          placeholder="0"
                          v-model="
                          lifeSituation.additionalIncomeDetails.inheritance
                        "
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                            class="sw-number_up"
                            onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                            class="sw-number_down"
                            onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 d-flex align-items-center justify-content-between mt-4">
                  <div class="col-6">
                    Interest / Dividend Income
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                          type="number"
                          placeholder="0"
                          v-model="lifeSituation.additionalIncomeDetails.interest"
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                            class="sw-number_up"
                            onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                            class="sw-number_down"
                            onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 d-flex align-items-center justify-content-between mt-4">
                  <div class="col-6">
                    Market Trading Profits
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                          type="number"
                          placeholder="0"
                          v-model="lifeSituation.additionalIncomeDetails.market"
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                            class="sw-number_up"
                            onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                            class="sw-number_down"
                            onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 d-flex align-items-center justify-content-between mt-4">
                  <div class="col-6">
                    Pension / Government Retirement benefit
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                          type="number"
                          placeholder="0"
                          v-model="lifeSituation.additionalIncomeDetails.pension"
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                            class="sw-number_up"
                            onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                            class="sw-number_down"
                            onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 d-flex align-items-center justify-content-between mt-4">
                  <div class="col-6">
                    Property
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                          id="lsSOWIncomeProperty"
                          type="number"
                          placeholder="0"
                          v-model="lifeSituation.additionalIncomeDetails.property"
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                            class="sw-number_up"
                            onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                            class="sw-number_down"
                            onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 d-flex align-items-center justify-content-between mt-4">
                  <div class="col-6">
                    Other
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                          id="lsSOWIncomeOther"
                          type="number"
                          placeholder="0"
                          v-model="lifeSituation.additionalIncomeDetails.other"
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                            class="sw-number_up"
                            onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                            class="sw-number_down"
                            onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-4">
                  <div
                      class="sw-textfield w-100 sw-label"
                  >
                    <input
                        id="lsSOWIncomeOtherReason"
                        type="text"
                        placeholder="Provide the reason here"
                        v-model="lifeSituation.additionalIncomeDetails.reason"
                        v-if="lifeSituation.additionalIncomeDetails.other > 0"
                    >
                  </div>

                  <div class="d-flex mt-45 flex-wrap bordered-wrapper pb-4">
                    <div class="col-12">
                      <div class="sw-form-progress">
                        <div class="swfp-bar"/>
                        <div
                            class="swfp-progress"
                            :style="{ width: addIncomePercent + '%' }"
                            :class="{
                            'swfp-progress-red': addIncomePercent != 100,
                          }"
                        />
                      </div>
                    </div>
                    <div
                        class="col-12 d-flex justify-content-between mt-4 sw-form-total"
                        :class="{ 'cl-red': addIncomePercent != 100 }"
                    >
                      <div>Total</div>
                      <div>
                        {{ addIncomePercent }}
                        <span>%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
            id="lsRegulatory"
            class="col-12 mt-5">
          <h3 class="ypd-typo-title">
            Regulatory information
          </h3>
          <div
              id="lsRegulatoryAffiliation"
              class="d-flex align-items-center bordered-wrapper flex-wrap">
            <h5 class="col-10 ypd-typo-text mt-5">
              Are you or anyone in your immediate family employed by or
              registered with a broker-dealer, investment advisor, futures
              commission merchant, hedge fund, exchange or other financial
              services firm (aka a "Financial Firm")?
            </h5>
            <div class="col-2 d-flex justify-content-end">
              <div class="sw-switch">
                <input
                    type="checkbox"
                    id="switch-2"
                    name="switch-2"
                    v-model="lifeSituation.regulatoryAffiliation"
                >
                <label for="switch-2"/>
              </div>
            </div>

            <template v-if="lifeSituation.regulatoryAffiliation">
              <div
                  data-test-id="lsRegulatoryAffiliationForm"
                  class="col-12 d-flex flex-wrap mt-4 mb-4"
                  v-for="(item, i) in lifeSituation.regulatoryAffiliationDetails"
                  :key="'addInc' + i"
              >
                <div class="col-md-6 col-12 pe-md-2">
                  <div
                      data-test-id="lsRegulatoryRelationts"
                      class="sw-label"
                      :class="{
                      'form-error':
                        v$.lifeSituation.regulatoryAffiliationDetails.$each
                          .$response.$data[i].relation.$error,
                    }"
                  >
                    <div class="sw-label-text">
                      Relationts
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                          :options="['Other', 'Spouse', 'Parent', 'Child']"
                          close-on-select
                          placeholder="Choose option"
                          class="sw-select"
                          v-model="item.relation"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-12 ps-md-2 mt-3 mt-md-auto">
                  <div
                      data-test-id="lsRegulatoryHolder"
                      class="sw-label"
                      :class="{
                      'form-error':
                        v$.lifeSituation.regulatoryAffiliationDetails.$each
                          .$response.$data[i].name.$error,
                    }"
                  >
                    <div class="sw-label-text">
                      Holder full name
                    </div>
                    <div class="d-flex swl-content">
                      <div class="sw-textfield w-100">
                        <input
                            type="text"
                            placeholder="Enter holder full name"
                            v-model="item.name"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-3">
                  <div
                      data-test-id="lsRegulatoryCompany"
                      class="sw-label"
                      :class="{
                      'form-error':
                        v$.lifeSituation.regulatoryAffiliationDetails.$each
                          .$response.$data[i].company.$error,
                    }"
                  >
                    <div class="sw-label-text">
                      Company
                    </div>
                    <div class="d-flex swl-content">
                      <div class="sw-textfield w-100">
                        <input
                            type="text"
                            placeholder="Enter company name"
                            v-model="item.company"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-12 pe-md-2 mt-3">
                  <div
                      data-test-id="lsRegulatoryCountry"
                      class="sw-label"
                      :class="{
                      'form-error':
                        v$.lifeSituation.regulatoryAffiliationDetails.$each
                          .$response.$data[i].country.$error,
                    }"
                  >
                    <div class="sw-label-text">
                      Company country
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                          :options="countries"
                          close-on-select
                          placeholder="Choose option"
                          class="sw-select"
                          v-model="item.country"
                          searchable
                          clear-on-select
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-12 ps-md-2 mt-3">
                  <div
                      data-test-id="lsRegulatoryState"
                      class="sw-label"
                      :class="{
                      'form-error':
                        v$.lifeSituation.regulatoryAffiliationDetails.$each
                          .$response.$data[i].state.$error,
                    }"
                  >
                    <div class="sw-label-text">
                      State/Province
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                          :options="item.country ? countryList[item.country] : []"
                          close-on-select
                          placeholder="Choose option"
                          class="sw-select"
                          v-model="item.state"
                          searchable
                          clear-on-select
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-12 pe-md-2 mt-3">
                  <div
                      data-test-id="lsRegulatoryCity"
                      class="sw-label"
                      :class="{
                      'form-error':
                        v$.lifeSituation.regulatoryAffiliationDetails.$each
                          .$response.$data[i].city.$error,
                    }"
                  >
                    <div class="sw-label-text">
                      Company city
                    </div>
                    <div class="d-flex swl-content">
                      <div class="sw-textfield w-100">
                        <input
                            type="text"
                            placeholder="Enter company city"
                            v-model="item.city"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-12 ps-md-2 mt-3">
                  <div
                      data-test-id="lsRegulatoryZIP"
                      class="sw-label"
                      :class="{
                      'form-error':
                        v$.lifeSituation.regulatoryAffiliationDetails.$each
                          .$response.$data[i].postal_code.$error,
                    }"
                  >
                    <div class="sw-label-text">
                      Postal code
                    </div>
                    <div class="d-flex swl-content">
                      <div class="sw-textfield w-100">
                        <input
                            type="text"
                            placeholder="Enter postal code"
                            v-model="item.postal_code"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-3">
                  <div
                      data-test-id="lsRegulatoryAddr"
                      class="sw-label"
                      :class="{
                      'form-error':
                        v$.lifeSituation.regulatoryAffiliationDetails.$each
                          .$response.$data[i].addr.$error,
                    }"
                  >
                    <div class="sw-label-text">
                      Company full address
                    </div>
                    <div class="d-flex swl-content">
                      <div class="sw-textfield w-100">
                        <input
                            type="text"
                            placeholder="Enter street address, building, office number, etc."
                            v-model="item.addr"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-12 pe-md-2 mt-3">
                  <div
                      data-test-id="lsRegulatoryPhone"
                      class="sw-label"
                      :class="{
                      'form-error':
                        v$.lifeSituation.regulatoryAffiliationDetails.$each
                          .$response.$data[i].phone.$error,
                    }"
                  >
                    <div class="sw-label-text">
                      Company phone
                    </div>
                    <div class="d-flex swl-content">
                      <div class="sw-textfield w-100">
                        <input
                            type="text"
                            placeholder="Enter company phone"
                            v-model="item.phone"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-12 ps-md-2 mt-3">
                  <div
                      data-test-id="lsRegulatoryEmail"
                      class="sw-label"
                      :class="{
                      'form-error':
                        v$.lifeSituation.regulatoryAffiliationDetails.$each
                          .$response.$data[i].email.$error,
                    }"
                  >
                    <div class="sw-label-text">
                      Company e-mail
                    </div>
                    <div class="d-flex swl-content">
                      <div class="sw-textfield w-100">
                        <input
                            type="text"
                            placeholder="Enter company e-mail"
                            v-model="item.email"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  id="lsRegulatoryAdd"
                  class="col-12 mt-3 mb-45"
                  @click="addRegAff"
              >
                <div class="d-flex align-items-center">
                  <div class="swi plus-square swi_mini d-inline-block me-2"/>
                  Add one more affiliated person
                </div>
              </div>
            </template>
          </div>

          <div
              id="lsRegulatoryOfficer"
              class="d-flex align-items-center bordered-wrapper py-4 flex-wrap">
            <h5 class="col-10 ypd-typo-text">
              Are you a director, a 10% shareholder or a policy-making officer
              of any publicly traded company?
            </h5>
            <div class="col-2 d-flex justify-content-end">
              <div class="sw-switch">
                <input
                    type="checkbox"
                    id="switch-3"
                    name="switch-3"
                    v-model="lifeSituation.regulatoryOfficer"
                >
                <label for="switch-3"/>
              </div>
            </div>

            <div
                data-test-id="lsRegulatoryOfficerForm"
                class="col-12 d-flex flex-wrap mt-4"
                v-if="lifeSituation.regulatoryOfficer"
            >
              <div class="col-12 mt-3">
                <div
                    class="sw-label"
                    :class="{
                    'form-error':
                      v$.lifeSituation.regulatoryOfficerSymbols.$error,
                  }"
                >
                  <div class="sw-label-text">
                    Stock symbols
                  </div>
                  <div class="d-flex swl-content">
                    <div class="sw-textfield w-100">
                      <input
                          id="lsRegulatoryOfficerStocks"
                          type="text"
                          placeholder="Enter comma separated list"
                          v-model="lifeSituation.regulatoryOfficerSymbols"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
              id="lsInvestmentExperience"
              class="col-12 mt-5">
            <h3 class="ypd-typo-title">
              Investment Experience
            </h3>
            <div
                class="col-10 ypd-typo-text"
            >
              What did you invest in before?
            </div>

            <div
                class="col-12 d-flex flex-wrap mt-4"
            >
              <div
                  data-test-id="investmentExperienceDetails"
                  class="col-12 mt-4"
                  v-for="item in lifeSituation.investmentExperienceDetails"
                  :key="'investExpDet' + item.asset_class"
                  :id="`investExpDet${item.asset_class}`"
              >
                <h5 class="col-12 mb-2 ypd-typo-subtext">
                  {{ item.asset_class }}
                </h5>
                <div class="col-12 d-flex flex-wrap justify-content-between">
                  <div class="col-md-4 col-12 pe-md-2">
                    <div class="sw-label">
                      <div class="sw-label-text">
                        Years of experience
                      </div>
                      <div
                          data-test-id="investmentExperienceYears"
                          class="d-flex swl-content">
                        <v-select
                            :options="yearsOfExperience"
                            close-on-select
                            placeholder="0 Years of Experience"
                            class="sw-select"
                            v-model="item.years_trading"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-12 px-md-1">
                    <div class="sw-label">
                      <div class="sw-label-text">
                        Trades per year
                      </div>
                      <div
                          data-test-id="investmentExperienceTrades"
                          class="d-flex swl-content">
                        <v-select
                            :options="tradesPerDay"
                            close-on-select
                            placeholder="0 Trades per Year"
                            class="sw-select"
                            v-model="item.trades_per_year"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-12 ps-md-2">
                    <div class="sw-label">
                      <div class="sw-label-text">
                        Knowledge level
                      </div>
                      <div
                          data-test-id="investmentExperienceKnowledge"
                          class="d-flex swl-content">
                        <v-select
                            :options="knowledgeLevels"
                            close-on-select
                            placeholder="No Knowledge"
                            class="sw-select"
                            v-model="item.knowledge_level"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
            class="col-12 mt-5"
            id="invObjScroll"
        >
          <h3
              class="ypd-typo-title"
              :class="{ 'form-error': invObjError || oneMoreCheck === 1 }"
          >
            Investment Objectives
          </h3>
          <div
              class="col-10 ypd-typo-text"
          >
            Your choices below determine the investment products that you can be approved to trade.
          </div>
          <h5
              class="mt-4 ypd-typo-small"
          >
            Recommended values turned on by default for your convenience.
          </h5>

          <div
              class="sw-label form-error"
              v-if="invObjError || oneMoreCheck < 2"
          >
            <div
                class="sw-label-text"
                v-if="oneMoreCheck === 0"
            >
              Choose at least one, please!
            </div>
            <div
                class="sw-label-text"
                v-if="oneMoreCheck === 1"
            >
              Please choose at least two values
            </div>
          </div>

          <div
              id="lsIOGrowth"
              class="d-flex align-items-center flex-wrap"
          >
            <div class="col-12 d-flex align-items-center flex-wrap mt-5">
              <h5 class="col-10 ypd-typo-text">
                Growth
              </h5>
              <div class="col-2 d-flex justify-content-end">
                <div class="sw-switch">
                  <input
                      type="checkbox"
                      id="io-switch-1"
                      name="io-switch-1"
                      v-model="lifeSituation.growth"
                      @change="checkForGrowth"
                  >
                  <label for="io-switch-1"/>
                </div>
              </div>
            </div>
            <h5
                class="ypd-typo-small mt-3"
            >
              To increase the principal value of your investments over time rather than seek current income. Investor
              assumes higher degree of risk.
            </h5>
          </div>

          <div
              id="lsIOHedging"
              class="d-flex align-items-center flex-wrap mt-4"
          >
            <div class="col-12 d-flex align-items-center flex-wrap mt-5">
              <h5 class="col-10 ypd-typo-text">
                Hedging
              </h5>
              <div class="col-2 d-flex justify-content-end">
                <div class="sw-switch">
                  <input
                      type="checkbox"
                      id="io-switch-2"
                      name="io-switch-2"
                      v-model="lifeSituation.hedging"
                      @change="checkForHedging"
                  >
                  <label for="io-switch-2"/>
                </div>
              </div>
            </div>
            <h5
                class="ypd-typo-small mt-3"
            >
              To take positions in a product to hedge or offset the risk in another product.
            </h5>
          </div>

          <div
              id="lsIOPreservation"
              class="d-flex align-items-center flex-wrap mt-4"
          >
            <div class="col-12 d-flex align-items-center flex-wrap mt-5">
              <h5 class="col-10 ypd-typo-text">
                Preservation of Capital and Income Generation
              </h5>
              <div class="col-2 d-flex justify-content-end">
                <div class="sw-switch">
                  <input
                      type="checkbox"
                      id="io-switch-3"
                      name="io-switch-3"
                      v-model="lifeSituation['preservation/income']"
                      @change="checkForIncome"
                  >
                  <label for="io-switch-3"/>
                </div>
              </div>
            </div>
            <h5
                class="ypd-typo-small mt-3"
            >
              To seek maximum safety and stability for your principal by focusing on securities and investments that
              carry a low degree of risk and/or To generate dividend, interest or other income instead of or in addition
              to long-term capital appreciation.
            </h5>
          </div>
          <div
              id="lsIOSpeculation"
              class="d-flex align-items-center flex-wrap mt-4">
            <div class="col-12 d-flex align-items-center flex-wrap mt-5">
              <h5 class="col-10 ypd-typo-text">
                Profits from Active Trading and Speculation
              </h5>
              <div class="col-2 d-flex justify-content-end">
                <div class="sw-switch">
                  <input
                      type="checkbox"
                      id="io-switch-4"
                      name="io-switch-4"
                      v-model="lifeSituation.speculation"
                      @change="checkForSpeculation"
                  >
                  <label for="io-switch-4"/>
                </div>
              </div>
            </div>
            <h5
                class="ypd-typo-small mt-3"
            >
              To increase the principal value of your investments by assuming substantially higher risk to your
              investment capital and/or To substantially increase the principal value of your investments by assuming
              substantially higher risk to your investment capital.
            </h5>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-auto mt-5">
        <!-- <router-link
          :to="{ name: 'AboutYou', params: { id: $route.params.id } }"
          class="btn btn-primary col-12 mt-3 px-4 button-padding"
        >
          Continue →
        </router-link>-->
        <div
            @click="continueNext"
            class="btn btn-primary col-12 mt-3 px-4 button-padding"
            id="lsNextStep"
        >
          Continue&nbsp;&nbsp; &rarr;
        </div>
      </div>
    </div>
  </MainLayout>
</template>


<script>
import MainLayout from "@/layouts/MainLayout.vue";
import {mapActions, mapGetters} from "vuex";
import useVuelidate from "@vuelidate/core";
import {required, requiredIf, helpers} from "@vuelidate/validators";
import {prepareWatchers} from "@/services/prepareWatchers";

var watchers = prepareWatchers("lifeSituation");

export default {
  setup: () => ({v$: useVuelidate()}),
  validations() {
    if (this.lifeSituation && this.lifeSituation.regulatoryAffiliation) {
      return {
        lifeSituation: {
          dependents: {required},
          employment: {required},
          employmentDetails: {
            employer: requiredIf(() => {
                  return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
                }
            ),
            occupation: requiredIf(() => {
                  return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
                }
            ),
            employer_business: requiredIf(() => {
                  return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
                }
            ),
            employer_address: {
              addr: requiredIf(() => {
                    return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
                  }
              ),
              city: requiredIf(() => {
                    return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
                  }
              ),
              state: requiredIf(() => {
                    return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
                  }
              ),
              country: requiredIf(() => {
                    return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
                  }
              ),
              postal_code: requiredIf(() => {
                    return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
                  }
              ),
            },
          },
          regulatoryOfficerSymbols: requiredIf(() => this.lifeSituation.regulatoryOfficer),
          regulatoryAffiliationDetails: {
            $each: helpers.forEach({
              relation: {
                required,
              },
              name: {
                required,
              },
              company: {
                required,
              },
              addr: {
                required,
              },
              city: {
                required,
              },
              state: {
                required,
              },
              country: {
                required,
              },
              postal_code: {
                required,
              },
              phone: {
                required,
              },
              email: {
                required,
              },
            }),
          },
        },
      };
    }

    return {
      lifeSituation: {
        dependents: {required},
        employment: {required},
        employmentDetails: {
          employer: requiredIf(() => {
                return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
              }
          ),
          occupation: requiredIf(() => {
                return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
              }
          ),
          employer_business: requiredIf(() => {
                return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
              }
          ),
          employer_address: {
            addr: requiredIf(() => {
                  return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
                }
            ),
            city: requiredIf(() => {
                  return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
                }
            ),
            state: requiredIf(() => {
                  return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
                }
            ),
            country: requiredIf(() => {
                  return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
                }
            ),
            postal_code: requiredIf(() => {
                  return ["Employed", "Self-employed"].includes(this.lifeSituation.employment)
                }
            ),
          },
        },
        regulatoryOfficerSymbols: requiredIf(() => this.lifeSituation.regulatoryOfficer),
        additionalIncomeDetails: {
          reason: requiredIf(() => this.lifeSituation.additionalIncomeDetails.other > 0),
        },
      },
    }
  },
  components: {
    MainLayout,
  },
  name: "LifeSituation",
  watch: {
    ...watchers,
    lifeSituation: {
      handler(newValue) {
        this.setValue({
          key: "lifeSituation",
          value: newValue,
        });
      },
      deep: true,
    },
    "lifeSituation.growth": function () {
      this.invObjError = !this.invObjValid
      var ls = this.lifeSituation;

      if (ls.growth && ls.hedging && !ls.speculation && !ls['preservation/income']) {
        this.lifeSituation.speculation = true;
      }
      if (ls.growth && ls.hedging && ls.speculation && ls['preservation/income']) {
        this.lifeSituation.speculation = true;
        this.lifeSituation['preservation/income'] = false;
      }
    },
    "lifeSituation.hedging": function () {
      this.invObjError = !this.invObjValid
      var ls = this.lifeSituation;
      if (ls.growth && ls.hedging && !ls.speculation && !ls['preservation/income']) {
        this.lifeSituation.speculation = true;
      }
      if (ls.growth && ls.hedging && ls.speculation && ls['preservation/income']) {
        this.lifeSituation.speculation = true;
        this.lifeSituation['preservation/income'] = false;
      }
      if (!ls.growth && ls.hedging && !ls.speculation && ls['preservation/income']) {
        this.lifeSituation.growth = true;
      }
    },
    "lifeSituation.preservation/income": function () {
      this.invObjError = !this.invObjValid
      var ls = this.lifeSituation;
      if (ls.growth && ls.speculation && ls['preservation/income']) {
        this.lifeSituation.speculation = false;
      }
      if (ls.growth && ls.hedging && !ls.speculation && !ls['preservation/income']) {
        this.lifeSituation.speculation = true;
      }

    },
    "lifeSituation.speculation": function () {
      this.invObjError = !this.invObjValid
      var ls = this.lifeSituation;
      if (!ls.growth && !ls.hedging && ls.speculation && ls['preservation/income']) {
        this.lifeSituation.growth = true;
        this.lifeSituation['preservation/income'] = false;
      }
      if (ls.growth && !ls.hedging && ls.speculation && ls['preservation/income']) {
        this.lifeSituation.growth = true;
        this.lifeSituation['preservation/income'] = false;
      }
    },
  },
  computed: {
    ...mapGetters(["onbForm", "countryList", "businessOccupationList"]),

    invObjValid() {
      return this.lifeSituation.growth || this.lifeSituation.hedging || this.lifeSituation['preservation/income'] || this.lifeSituation.speculation;
    },
    regAffValid() {
      return this.lifeSituation.regulatoryAffiliation;
    },
    countries() {
      return Object.keys(this.countryList);
    },
    businessOccupations() {
      return Object.keys(this.businessOccupationList);
    },
    businessOccupationsOptions() {
      if (!this.lifeSituation) return [];
      if (this.lifeSituation.employmentDetails.employer_business) {
        return this.businessOccupationList[
            this.lifeSituation.employmentDetails.employer_business
            ];
      }
      return [];
    },
    states() {
      if (!this.lifeSituation) return [];
      if (this.lifeSituation.employmentDetails.employer_address.country) {
        return this.countryList[
            this.lifeSituation.employmentDetails.employer_address.country
            ];
      }
      return [];
    },
    addIncomePercent() {
      const addInc = this.lifeSituation.additionalIncomeDetails;

      let values = [];

      Object.keys(addInc).forEach((value) => {
        // if (1 * addInc[value] > 0) {
        values.push(addInc[value]);
        // }
      });

      const sum = values.reduce((accumulator, a) => {
        return isNaN(a) ? accumulator : accumulator + a;
      }, 0);
      return sum;
    },
    oneMoreCheck() {
      return [this.lifeSituation.growth, this.lifeSituation.hedging, this.lifeSituation['preservation/income'], this.lifeSituation.speculation].filter(Boolean).length;
    },
  },
  data: () => {
    return {
      checkboxError: null,
      lifeSituation: null,
      employmentTypes: [
        "Employed",
        "Retired",
        "Self-employed",
        "Unemployed",
        "Studying (Student/Intern)",
        "At-home Trader (No Other Occupation)",
      ],
      yearsOfExperience: [
        "0 Years of Experience",
        "1 Year of Experience",
        "2 Years of Experience",
        "3 Years of Experience",
        "4 Years of Experience",
        "5 Years of Experience",
        "6 - 10 Years of Experience",
        "> 10 Years of Experience",
      ],
      tradesPerDay: [
        "0 Trades per Year",
        "1 - 10 Trades per Year",
        "11 - 25 Trades per Year",
        "26 - 50 Trades per Year",
        "51 - 100 Trades per Year",
        "> 100 Trades per Year",
      ],
      knowledgeLevels: [
        "No Knowledge",
        "Limited Knowledge",
        "Good Knowledge",
        "Extensive Knowledge",
      ],
      invObjError: false
    };
  },
  methods: {
    ...mapActions(["setValue", "fetchCountries", "fetchBusinessOccupation"]),
    addRegAff() {
      this.lifeSituation.regulatoryAffiliationDetails.push({
        relation: null,
        name: "",
        company: "",
        addr: null,
        city: null,
        state: null,
        country: null,
        postal_code: null,
        phone: null,
        email: null,
      });
    },
    validate() {
      this.v$.$validate();

      if (this.v$.$error) {
        setTimeout(() => {
          document.getElementsByClassName("form-error")[0].scrollIntoView({
            behavior: "smooth",
          });
        }, 100);
      }

      if (this.addIncomePercent < 100) {
        document.getElementById("totalScroll").scrollIntoView({
          behavior: "smooth",
        });
        return false;
      }

      if (!this.invObjValid || this.oneMoreCheck < 2) {
        document.getElementById("invObjScroll").scrollIntoView({
          behavior: "smooth",
        });
        this.invObjError = true;
        return false;
      }

      return !this.v$.$invalid;
    },
    async continueNext() {
      if (this.validate()) {
        this.$store.state.form.lifeSituation.completed = 1;
        await this.$store.dispatch("storeForm");

        this.$router.push({
          name: "AboutYou",
          params: {id: this.$route.params.id},
        });
      }
    },

    // checkForGrowth() {
    //   var ls = this.lifeSituation;
    //   if (ls.growth && ls.hedging && ls.speculation && ls["preservation/income"]) {
    //     ls["preservation/income"] = false;
    //     return;
    //   }
    //   if (ls.growth && ls.hedging && ls.speculation && !ls["preservation/income"]) {
    //     return;
    //   }

    // },
    // checkForHedging() {
    //   if (!this.lifeSituation.hedging) return;
    //   if (this.lifeSituation.growth && this.lifeSituation.speculation && this.lifeSituation["preservation/income"]) {
    //     this.lifeSituation["preservation/income"] = false;
    //     return;
    //   }
    //   if (!this.lifeSituation.growth && this.lifeSituation.speculation && this.lifeSituation["preservation/income"]) {
    //     this.lifeSituation["preservation/income"] = false;
    //     return;
    //   }

    // },
    // checkForIncome() {
    //   if (!this.lifeSituation["preservation/income"]) return;
    //   this.lifeSituation.speculation = false;
    // },
    // checkForSpeculation() {
    //   if (!this.lifeSituation.speculation) return;
    //   this.lifeSituation["preservation/income"] = false;
    // },
  },
  async mounted() {
    await this.fetchCountries();
    await this.fetchBusinessOccupation();
    this.lifeSituation = this.onbForm.lifeSituation;
    // console.log(this.v$.lifeSituation.regulatoryAffiliationDetails.$each);
  },
};
</script>